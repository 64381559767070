.lesson-sharing-view {
  background: #ffffff;
  font-size: 1.3em;
  overflow-y: hidden;
}

.lesson-sharing-view .progress-bar-container {
  width: 100%;
  background-color:#FAFAFA;
  padding: 6px 12px;
  border-bottom: 0.5px solid #CCC;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 10;
  position: relative;
}

.lesson-sharing-view .next-button {
  animation: fadein;
  animation-duration: 1s;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.lesson-sharing-view .progress-bar-outline {
  background-color: transparent;
  border: 0.5px solid #CCC;
  height: 6px;
  border-radius: 3px;
  width: calc(100% - 24px);
}

.lesson-sharing-view .progress-bar {
  background-color:#237882CC;
  border-radius: 2px;
  height: 100%;
}

.lesson-sharing-view .lesson-outline {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  border: 1px solid #BBB; 
  border-radius: 6px;
  padding: 0px 0px;
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.lesson-sharing-view .lesson-outline li {
  cursor: pointer;
}

.lesson-sharing-view .lesson-outline li.active {
  color: teal;
  font-weight: bold;
  cursor: default;
}

.lesson-sharing-view .lesson-outline li.not-there-yet {
  color: #CCC;
}

.lesson-sharing-view .hockets-area {
  max-width: 950px;
  padding-bottom: 1px;
}

.lesson-sharing-view .landing-top-bar {
  padding: 12px;
  text-align: right;
  background: #237882;
  color: #FFF;
  z-index: 10;
  position: relative;
}

.lesson-sharing-view .landing-top-bar .title {
  font-size: 1.3em;
  float: left;
}

.lesson-sharing-view .landing-top-bar a {
  text-decoration: none;
  color: inherit;
}

.positioned {
  position: relative;
}

.youtube-space-above {
  margin-top: 450px;
}

.youtube-float {
  position: fixed;
  top: 88px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  width: 95%;
  margin-right: 2.5%;
  margin-left: 2.5%;
  max-width: 775px;
}

.youtube-embed-mobile {
  max-height: 500px;
  width: 95%;
  z-index: 1000;
}

@media only screen and (max-width: 600px) {
  .youtube-embed-mobile {
    max-height: 300px;
  }
}

.lesson-sharing-view .youtube-column {
  width: 60%;
  flex: 1.5;
}

.youtube-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 25px;
  margin-left: 2%;
  margin-top: 2%;
}

.youtube-embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* display: block;
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
  width: 90%; */
}

.clustered-message-card p {
  margin-block-start: 3px;
  margin-block-end: 3px;
}

.courses-area .project-card h2 {
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
}

.courses-area .project-card p {
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  margin-bottom: 20px;
  margin-top: 0px;
}

.courses-area .project-card p.subtext {
  margin-bottom: 12px;
}

.courses-area .project-card p.subtext.active {
  margin-bottom: 2px;
}

.lesson-sharing-view .lesson-title {
  margin-bottom: 50px;
}

.lesson-sharing-view .super-list {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.lesson-sharing-view .description-text {
  margin-bottom: 70px;
}

@keyframes fadeinfrombelow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translate(0, 40px);
  }
  100% {
    opacity: 1;
  }
}

.lesson-sharing-view .ql-snow .ql-editor {
  font-size: 14pt;
}

.lesson-sharing-view .shared-lesson-card {
  width: 90%;
  position: relative;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  border: 1px solid transparent;
  transition: border 0.2s linear 1s;
  border-radius: 8px;
  animation-name: fadeinfrombelow;
  animation-duration: 0.5s;
}

.lesson-sharing-view .shared-lesson-card:last-child {
  margin-bottom: 80px;
}

.lesson-sharing-view .shared-lesson-card .ql-container.ql-snow {
  border: none;
}

.lesson-sharing-view .chat-input-area .ql-container.ql-snow {
  border: none;
}

.lesson-sharing-view .chat-input-area {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
}

.lesson-sharing-view .editor {
  border: 1px solid #CCC;
  border-radius: 8px;
  background: white;
  flex-grow: 1;
}

.lesson-sharing-view .ql-editor.ql-blank:before {
  color: #999;
}

.lesson-sharing-view .chat-input-area .chat-controls {
  flex-shrink: 0;
  flex-basis: auto;
}

.lesson-sharing-view .message-link {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  transform: scale(0.7);
  display: none;
  z-index: 2;
}

.lesson-sharing-view .message-link.copied {
  background: white;
  padding: 5px;
  border: 1px solid #BBB;
  border-radius: 4px;
  font-size: 12pt;
  color: #777;
}

.lesson-sharing-view .shared-lesson-card:hover .message-link {
  display: inline;
}

.lesson-sharing-view .shared-lesson-card:hover {
  border: 1px solid #EFEFEF;
}



.sharing-send-icon {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  color: #2a9097;
}

.sharing-send-icon.active {
  cursor: pointer;
}

.sharing-send-icon.active:hover {
  color: #f6a801;
}

.sharing-send-icon.inactive {
  color: #CCC;
}

.sharing-chat-controls {
  flex-basis: auto;
  margin-left: 12px;
}

.from-student {
  background: #3c838a;
  color: white;
  border: 1px solid black;
}

.from-student .comments {
  color: black;
}

.from-student code {
  background-color: #435152!important;
  color: #fff;
}

.from-student a {
  color: #dce2e8!important;
}

.from-student h3 {
  color: #bbb;
  margin-bottom: 0;
}

.lesson-sharing-view .chat-input-container {
  border-top: 1px solid #CCC;
}

.show-all-button {
  position: relative;
  float: right;
  margin-right: 10px;
  top: 10px;
  font-size: 10px;
  color: #BBB;
  cursor: pointer;
}

.lesson-sharing-view .suggestion-chips {
  font-size: 12pt;
  margin-bottom: 40px;
}

.shared-lesson-card .juniper-output {
  border-radius: 0px 0px 7px 7px;
}

.lesson-sharing-view.copy-mode ::selection { background: transparent; }
.lesson-sharing-view.copy-mode .chat-input-area p::selection { background: #B4D5FE; }
